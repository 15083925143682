<template>
<!--  <div class="cont">-->
<!--    <h1>Purchases</h1>-->
<!--  </div>-->
<div class="my-5 py-5">
  <div class="my-5 py-5">
    <h1 class="text-primary align-content-center">Orders</h1>
    <table class=" m-2 table table-responsive  table-bordered">
      <thead>
      <tr>
        <th>Reference</th>
        <th>Address</th>
        <th>Date</th>
        <th>Delivery Status</th>
        <th>Items</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="order in orders">
        <td>{{order.reference}}</td>
        <td class="text-truncate">{{order.address}}</td>
        <td>{{order.created_at.slice(0, 10)}}</td>
        <td>{{order.delivery_status}}</td>
        <td><p v-for="item in order.items">{{item.item_name}}  ${{item.item_price}}</p></td>
        <td><button v-if="order.delivery_status==='pending'" @click="deliver(order.id)" class="btn btn-success">Mark as Delivered</button><button v-if="order.delivery_status==='delivered'" @click="undeliver(order.id)" class="btn btn-danger">Mark as not Delivered</button></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


</template>

<script>


import axios from "axios";
import store from "@/store";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'Purchases',
  data() {
    return {
      configs: null,
      orders: null,
      token:null,

    }
  },
  mounted() {
    this.token = JSON.parse(localStorage.getItem('token'));
    this.configs = {headers: {'Authorization': 'Bearer ' + this.token.plaintext}};
    this.loadOrders();

  },
  methods:{
    async deliver(id) {
      await axios.post(store.state.api + 'mark-delivered',{order_id:id}, this.configs,)
          .then(response => {
            if(response.data.message === 'success') {
              this.loadOrders();
              toast.success('Order marked as delivered.')
            }
            else{
              toast.warning('Failed to update order status')
            }

            console.log(response);
          })
          .catch(error => {
            toast.warning('Failed to update order status')
            console.log(error);
          })
    },
    async undeliver(id) {
      await axios.post(store.state.api + 'mark-undelivered',{order_id:id}, this.configs,)
          .then(response => {
            if(response.data.message === 'success') {
              this.loadOrders();
              toast.success('Order marked as not delivered.')
            }
            else{
              toast.warning('Failed to update order status')
            }
          })
          .catch(error => {
            toast.warning('Failed to update order status')
          })
    },
    async loadOrders() {
      await axios.get(store.state.api + 'order', this.configs)
          .then(response => {
            this.orders = response.data.orders;
          })
          .catch(error => {

            console.log(error);
          })
    }

  }
}
</script>

<!--<style scoped>-->
<!--.cont {-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  justify-content: center;-->
<!--  height: 100vh;-->
<!--}-->

<!--h1 {-->
<!--  font-size: 3em;-->
<!--  text-align: center;-->
<!--}-->
<!--</style>-->
<!-- Add "scoped" attribute to limit CSS to this component only -->

